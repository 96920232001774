@font-face {
	font-family: HomemadeApple;
	src: url(./fonts/HomemadeApple-Regular.ttf);
}
@font-face {
	font-family: Gasalt;
	src: url(./fonts/Gasalt-Black.ttf);
}
@font-face {
	font-family: Dawning;
	src: url(./fonts/DawningofaNewDay-Regular.ttf);
}
@font-face {
	font-family: CedarvilleCursive;
	src: url(./fonts/CedarvilleCursive-Regular.ttf);
}
@font-face {
	font-family: Caveat;
	src: url(./fonts/Caveat-Regular.ttf);
}
@font-face {
	font-family: LeagueScript;
	src: url(./fonts/LeagueScript-Regular.ttf);
}
@font-face {
	font-family: IndieFlower;
	src: url(./fonts/IndieFlower-Regular.ttf);
}
@font-face {
	font-family: Liu;
	src: url(./fonts/LiuJianMaoCao-Regular.ttf);
}
@font-face {
	font-family: NothingYouCouldDo;
	src: url(./fonts/NothingYouCouldDo-Regular.ttf);
}
@font-face {
	font-family: Lato;
	src: url(./fonts/Poppins-Regular.otf);
}
@font-face {
	font-family: Luckiest Guy;
	src: url(./fonts/LuckiestGuy-Regular.ttf);
}

@font-face {
	font-family: Enola;
	src: url(./fonts/Enola-Regular.ttf);
}
@font-face {
	font-family: RGhandwritten;
	src: url(./fonts/Rghandwritten-Regular.ttf);
}

@font-face {
	font-family: RayFont;
	src: url(./fonts/RayFont-Regular.ttf);
}

@font-face {
	font-family: Moody;
	src: url(./fonts/Moody-Regular.ttf);
}

@font-face {
	font-family: Norm;
	src: url(./fonts/Norm-Regular.ttf);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	box-sizing: border-box !important;
	transition: all 0.3s ease-out;
}

html {
	height: 100%;
	width: 100%;
	scroll-behavior: smooth;
}

body {
	background-color: #ffffff !important;
	width: 100%;
	height: 100%;
	color: #1e1e1e !important;

	font-family: Montserrat;
}

@font-face {
	font-family: Ruled1;
	src: url(./fonts/Lato-Light.ttf);
}
@font-face {
	font-family: Ruled2;
	src: url(./fonts/Lato-Light.ttf);
}
@font-face {
	font-family: OnlyMargin;
	src: url(./fonts/Lato-Light.ttf);
}
@font-face {
	font-family: Blank1;
	src: url(./fonts/Lato-Light.ttf);
}
@font-face {
	font-family: Blank2;
	src: url(./fonts/Lato-Light.ttf);
}

body::-webkit-scrollbar {
	width: 12px;
}

body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 8px rgba(12, 11, 11, 0.7);
	box-shadow: inset 0 0 8px rgba(12, 11, 11, 0.7);
	border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 13px rgba(31, 113, 180, 5);
	box-shadow: inset 0 0 13px rgba(31, 113, 180, 5);
}

::selection:not(textarea) {
	color: none;
	background: none;
}

::-moz-selection:not(textarea) {
	color: none;
	background: none;
}

.root {
	overflow: hidden;
}
