// color variables used in the app.

$white-light-tint: rgba(255, 255, 255, 0.3);
$white-thick-tint: rgba(255, 255, 255, 0.9);
$white: #fff;
$bluish-white: #bcdbee;
$black-light-tint: rgba(0, 0, 0, 0.302);
$black-thick-tint: rgba(0, 0, 0, 0.871);
$black: #000;
$light-pink: #bfdbb4;
$light-grey: #fcfcfc;
$medium-dark-grey: #444;
$dark-grey: #222;
$grey: grey;
$light-blue: #77cae5;
$steel-blue: #4e89ae;
$royal-blue: #4285f4;
$navy-blue: #1963db;
$dark-blue: #3d1f90;
$prussian-blue: #003153;
$light-purple: #376f92;
$purple: #103f5f;
$red: red;
$gold: gold;
$green: green;
$light-green-tint: rgba(245, 160, 30, 0.7);
