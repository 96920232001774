.preloader {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #1f1939;
	height: 100vh;
	width: 100%;
	padding: 0;
	padding-bottom: 120px;

	.circle {
		display: inline-block;
		width: 15px;
		height: 15px;
		background-color: #1f1939;
		border-radius: 50%;
		animation: loading 1s ease-in-out infinite;
		transform-origin: bottom center;
		position: relative;
	}
	.circle1 {
		animation-delay: 0.1s;
	}
	.circle2 {
		animation-delay: 0.2s;
	}
	.circle3 {
		animation-delay: 0.3s;
	}
	.circle4 {
		animation-delay: 0.4s;
	}
	.circle5 {
		animation-delay: 0.5s;
	}
	.circle6 {
		animation-delay: 0.6s;
	}
	.circle7 {
		animation-delay: 0.7s;
	}
	.circle8 {
		animation-delay: 0.8s;
	}
}
@keyframes loading {
	0% {
		transform: translateY(0px);
		background-color: #1f1939;
	}
	30% {
		transform: translateY(50px);
		background-color: #cccccc;
	}
	50% {
		transform: translateY(50px);
		background-color: #cccccc;
	}
	100% {
		transform: translateY(0px);
		background-color: #1f1939;
	}
}
